body{
    background-color: #eee!important;
}
.side-navbar {
    width: 184px;
    height: 100%;
    position: fixed;
    margin-left: -300px;
    background-color: #4a556d;
    transition: 0.5s;
    z-index: 10;
}
.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #ffffff26;
}
.my-container {
    transition: 0.4s;
}
.active-nav {
    margin-left: 0;
}
/* for main section */
.active-cont {
    margin-left: 180px;
}
#menu-btn {
    background-color: #3e3e3e;
    color: #fff;
    margin-left: -8px;
}
.pagination>li{margin:0;padding:0.1rem!important;}
li>a.page-link{padding: 0.4rem;}